var toogleNav = $('nav button')
var linkList = $('nav ul')
var navbar = $('nav')

const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style

//Expand navbar and change icon if burger clicked
toogleNav.on('click', (e) => {
    if (toogleNav.text().charCodeAt(0) == 10006) {
        toogleNav.text(String.fromCharCode(9776))
        navbar.removeClass('expanded')
    } else {
        toogleNav.text(String.fromCharCode(10006))
        navbar.addClass('expanded')
    }
})

var $window = $(window)

//Change navbar class if scrolled
$window.on('scroll', function () {
    var scrolled = $window.scrollTop()

    if (scrolled > 70) {
        navbar.removeClass('not_scrolled')
    } else {
        navbar.addClass('not_scrolled')
    }
})

//Scroll with navbar height offset when clicking on a link
$('a[href^="#"]:not(a[href$="#"])').on('click', function (e) {
    e.preventDefault()

    var topOfElement = $(this.getAttribute('href'))[0].offsetTop - 47
    if (supportsNativeSmoothScroll) {
        window.scrollTo({ top: topOfElement, behavior: 'smooth' })
    } else {
        window.scrollTo(topOfElement, topOfElement)
        console.log('test')
    }

    toogleNav.text(String.fromCharCode(9776))
    navbar.removeClass('expanded')
})
