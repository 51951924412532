import "/node_modules/leaflet/dist/leaflet.css";
import "leaflet";

import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";
import "leaflet-gesture-handling";

var marker = require("leaflet/dist/images/marker-icon.png");
var marker2x = require("leaflet/dist/images/marker-icon-2x.png");
var markerShadow = require("leaflet/dist/images/marker-shadow.png");

var mymap = L.map("map", {
    center: [48.64393, -2.86599],
    zoom: 13,
    gestureHandling: true,
    scrollWheelZoom: "center",
});

L.tileLayer(
    "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoicGNhbmNvaW4iLCJhIjoiY2trdWJibWN6MHdvaTJucGIycWNqOWJxeiJ9.O9310EFDUcDVa-bMfURCOw",
    {
        maxZoom: 18,
        attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, ' +
            '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, ' +
            'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        id: "mapbox/streets-v11",
        token:
            "pk.eyJ1IjoicGNhbmNvaW4iLCJhIjoiY2trdWJibWN6MHdvaTJucGIycWNqOWJxeiJ9.O9310EFDUcDVa-bMfURCOw",
    }
).addTo(mymap);

var icon = L.icon({
    iconUrl: marker,
    iconRetinaUrl: marker2x,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
});

var marker = L.marker([48.64393, -2.86599], { icon: icon }).addTo(mymap);

marker.bindPopup(
    "<b>Anne de Quelen Cancoin</b><br>1 rue du Moulin-Veil<br>22410 Plourhan"
);
